import Pagination from '@/shared/models/pagination.model';
import { QueryParams } from '@/shared/models/query-params.type';
import qs from 'qs';

export default class RuleApiFilter extends Pagination {
  tags?: string[];
  name?: string;
  message?: string;
  movementTypes?: string[];
  criticismId?: string[];

  constructor() {
    super();
  }

  bindWithQuery(queryParams: QueryParams<RuleApiFilter>): void {
    super.bindWithQuery(queryParams as Pagination);
    if (queryParams.tags) {
      const { tags } = qs.parse({ tags: queryParams.tags } as any, { parseArrays: true });
      const { movementTypes } = qs.parse({ tags: queryParams.movementTypes } as any, { parseArrays: true });
      const { criticismId } = qs.parse({ tags: queryParams.criticismId } as any, { parseArrays: true });
      const { name } = qs.parse({ tags: queryParams.name } as any, { parseArrays: false });
      const { message } = qs.parse({ tags: queryParams.message } as any, { parseArrays: false });

      this.tags = tags as string[];
      this.movementTypes = movementTypes as string[];
      this.criticismId = criticismId as string[];
      this.name = name as string;
      this.message = message as string;
    }
  }

  toHttpParams(): QueryParams<RuleApiFilter> {
    const params: QueryParams<RuleApiFilter> = {};

    if (Array.isArray(this.tags) && this.tags.length > 0) {
      params.tags = this.tags;
    }

    if (Array.isArray(this.movementTypes) && this.movementTypes.length > 0) {
      params.movementTypes = this.movementTypes;
    }

    if (Array.isArray(this.criticismId) && this.criticismId.length > 0) {
      params.criticismId = this.criticismId;
    }

    if (!!this.name) {
      params.name = this.name;
    }

    if (!!this.message) {
      params.message = this.message;
    }

    return params;
  }
}
