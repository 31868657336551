import { RuleTranslations } from "../models/rule-translaction-model";

export const ptBR: RuleTranslations = {
  title: 'Regras Gerais',
  self: 'Regra',
  fields: {
    name: {
      label: 'Buscar por nome',
      title: 'Nome da Regra',
      messages: {
        errors: {
          search: 'Ocorreu um erro ao buscar as regras.'
        }
      },
    },
    message: {
      label: 'Buscar por mensagem',
      title: 'Mensagem',
    },
    tag: {
      label: 'Buscar por categoria',
      title: 'Categoria',
      messages: {
        errors: {
          search: 'Ocorreu um erro ao buscar as tags.'
        },
        helper: 'Digite a categoria para incluir ou criar',
        helperKey: 'Pressione ENTER para incluir uma nova',
      },
    },
    movementType: {
      label: 'Buscar por tipo de movimentação',
      title: 'Tipo de movimentação',
    },
    field: {
      label: 'Buscar por campo criticado',
      title: 'Campo criticado',
    },
    ruleType: {
      label: 'Buscar por tipo de regra',
      title: 'Tipo de regra',
    },
    criticism: {
      label: 'Buscar por crítica',
      title: 'Crítica',
      messages: {
        errors: {
          search: 'Ocorreu um erro ao buscar as críticas.'
        },
      },
    },
  },
  table: {
    emptyMessage: 'Você não tem nenhuma regra',
    itemsPerPageText: 'Registros por página',
  },
  conditions: {
    type: 'Tipo',
    value: 'Valor',
    param: 'Parâmetro',
    operator: 'Operador',
    firstField: 'Campo 1',
    secondField: 'Campo 2',
    thirdField: 'Campo 3',
    addCondition: 'Adicionar condição',
    deleteCondition: 'Remover condição',
    addSubCondition: 'Adicionar Sub-Condição',
    dataSource: 'Fonte de dados',
    messages: {
      helperKeyNewValue: 'Pressione ENTER para incluir um novo valor',
      helperKeyNewDate: 'Pressione ENTER para incluir uma nova data no formato "dia/mês/ano"',
      placeholderDate: 'Data no formato dia/mês/ano"',
      noDataText: 'Selecione a fonte de dados'
    }
  },
  new: 'Nova Regra',
  edit: 'Editar Regra',
  create: 'Criar Regra',
  delete: 'Excluir Regra',
  copy: 'Copiar Regra',
  messages: {
    confirmDelete: 'Tem certeza que deseja excluir a regra abaixo?',
    ruleDeleted: 'Regra deletada com sucesso',
    ruleDeleteError: 'Erro ao excluir regra. Verifique se está vinculada a outras atividades.',
  },
  movementTypeOptions: {
    implantation: 'Implantação',
    inclusion: 'Inclusão',
    exclusion: 'Exclusão',
    alteration: 'Alteração',
  },
  ruleTypeOptions: {
    dynamic: 'Dinâmica',
    script: 'Script',
    groovy: 'Groovy'
  },
  scriptLanguage: 'Linguagem do script',
  defaultValueType: 'Tipo de valor padrão',
  value: 'Valor',
  description: 'Descrição',
  toCreateThisCategory: 'para criar esta categoria',
};
