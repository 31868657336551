
import {
  VuexModule, Module, getModule, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import { ContractModel } from './contract-types';

@Module({
  namespaced: true, name: 'contract', dynamic: true, store,
})
class ContractStore extends VuexModule {
  data: ContractModel = new ContractModel();

  get contracts() {
    return this.data.contracts;
  }

  @Mutation
  setContracts(contracts: []) {
    this.data.contracts = contracts;
  }

  @Mutation
  resetModule() {
    this.data.contracts = [];
  }
}

export default getModule(ContractStore);