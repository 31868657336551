
import {
  VuexModule, Module, getModule, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import { InsuranceCarrierModel } from './insurance-carrier-types';

@Module({
  namespaced: true, name: 'insurance-carrier', dynamic: true, store,
})
class InsuranceCarrierStore extends VuexModule {
  data: InsuranceCarrierModel = new InsuranceCarrierModel();

  get insuranceCarriers() {
    return this.data.insuranceCarriers;
  }

  @Mutation
  setInsuranceCarriers(insuranceCarriers: []) {
    this.data.insuranceCarriers = insuranceCarriers;
  }

  @Mutation
  resetModule() {
    this.data.insuranceCarriers = [];
  }
}

export default getModule(InsuranceCarrierStore);