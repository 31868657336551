
import {
  VuexModule, Module, getModule, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import { MovementTypeModel } from './movement-type-types';

@Module({
  namespaced: true, name: 'movement-type', dynamic: true, store,
})
class MovementTypeStore extends VuexModule {
  data: MovementTypeModel = new MovementTypeModel();

  get movementTypes() {
    return this.data.movementTypes;
  }

  @Mutation
  setMovementTypes(movementTypes: []) {
    this.data.movementTypes = movementTypes;
  }

  @Mutation
  resetModule() {
    this.data.movementTypes = [];
  }
}

export default getModule(MovementTypeStore);