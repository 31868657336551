// External library imports
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import VueTheMask from 'vue-the-mask';
import { Ripple } from 'vuetify/lib/directives';
import { VueMaskDirective, VueMaskFilter } from 'v-mask';
import 'reflect-metadata';

// Local imports
import App from './App.vue';
import router from './router';
import store from './store/index';
import vuetify from './plugins/vuetify';
import { configToasted } from './plugins/toasted';
import { configFilters } from './plugins/custom-filters';
import i18n from './plugins/i18n';

// Initial configurations
Vue.config.productionTip = false;
configToasted(Vue);
configFilters(Vue);
Vue.use(SimpleVueValidation);
Vue.use(VueTheMask);
Vue.directive('mask', VueMaskDirective);
Vue.directive('ripple', Ripple);
Vue.filter('mask', VueMaskFilter);

const vue = new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (create) => create(App),
});

router.onReady(() => {
  vue.$mount("#app");
});
