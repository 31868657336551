
import {
  VuexModule, Module, getModule, Mutation,
} from 'vuex-module-decorators';
import store from '@/store/index';
import {
  CalendarMovementConfig,
  CalendarMovementModel,
  CalendarMovementForm,
  CalendarMovement,
} from './calendar-movement-types';

@Module({
  namespaced: true, name: 'calendar-movement', dynamic: true, store,
})
class CalendarMovementStore extends VuexModule {
  data: CalendarMovementModel = new CalendarMovementModel();

  get calendarMovements() {
    return this.data.calendarMovements;
  }

  get calendarForm() {
    return this.data.form;
  }

  get showForm() {
    return this.data.displayForm;
  }

  get loading() {
    return this.data.loading;
  }

  @Mutation
  newCalendarConfigurationForm() {
    this.data.form = {
      carrierId: undefined,
      configs: []
    };
  }

  @Mutation
  setFormField(calendarMovementObject: CalendarMovementForm) {
    if (!this.data.form) return;

    this.data.form = {
      ...this.data.form,
      ...calendarMovementObject,
    };
  }

  @Mutation
  setNewConfig() {
    if (!this.data.form) return;

    const config = {
      movementTypes: [],
      flFutureWindow: false,
      flRetroactiveWindow: false,
      daysOfWindow: 120,
      flContractCutOffDate: false,
      flContractStartDate: false,
      specificDays: [],
    }

    if (this.data.form?.configs && this.data.form?.configs.length > 0) {
      this.data.form?.configs?.unshift(config);
    } else {
      this.data.form.configs = [config];
    }
  }

  @Mutation
  cloneFormConfiguration(config: CalendarMovementConfig) {
    this.data.form?.configs?.unshift({
      movementTypes: [],
      flFutureWindow: config.flFutureWindow,
      flRetroactiveWindow: config.flRetroactiveWindow,
      daysOfWindow: config.daysOfWindow,
      flContractCutOffDate: config.flContractCutOffDate,
      flContractStartDate: config.flContractStartDate,
      specificDays: config.specificDays,
    });
  }

  @Mutation
  removeFormConfiguration(conf: CalendarMovementConfig) {
    const configIndex = this.data.form?.configs?.findIndex((config) => config.calendarId === conf.calendarId);

    if (configIndex !== -1 && typeof configIndex !== 'number') return;

    if (this.data.form?.configs && this.data.form.configs[configIndex]) {
      this.data.form.configs.splice(configIndex, 1);
    }
  }

  @Mutation
  resetForm() {
    this.data.form = {};
  }

  @Mutation
  setCalendarMovements(calendarMovements: CalendarMovement[]) {
    this.data.calendarMovements = calendarMovements;
  }

  @Mutation
  displayForm(display: boolean) {
    this.data.displayForm = display;
  }

  @Mutation
  displayLoading(load: boolean) {
    this.data.loading = load;
  }

  @Mutation
  resetModule() {
    this.data.calendarMovements = [];
    this.data.displayForm = false;
    this.data.loading = false;
  }
}

export default getModule(CalendarMovementStore);