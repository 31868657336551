export class Rule {
  id?: number;

  number!: number;

  version!: number;

  description?: string;

  dataSource: string;

  name!: string;

  idLastVersion?: number;

  defaultType!: string;

  defaultValue?: string;

  configValue?: string;

  descriptionDefaultValue?: string;

  conditions!: Condition[];

  tags!: string[];

  idRule?: number;

  type?: any;

  scriptLanguage?: string;

  scriptLanguageDescription?: string;

  script?: string;

  implantation?: boolean;

  inclusion?: boolean;

  exclusion?: boolean;

  alteration?: boolean;

  field?: string;

  criticismId?: number | null;

  movementTypes: { code: string; name: string; description: string; id: number }[];

  constructor() {
    this.name = '';
    this.description = '';
    this.dataSource = 'registro_movimentacao';
    this.type = 0;
    this.script = '';
    this.defaultValue = '';
    this.criticismId = null;
    this.conditions = [];
    this.movementTypes = [];
  }
}

export class Field {
  id!: number;

  type!: string;

  value!: string;

  order!: number;

  name!: string;

  parameters!: Field[];

  valuein!: ValueIn[];

  constructor(order: number) {
    this.type = '';
    this.name = '';
    this.order = order;
    this.parameters = [];
    this.value = '';
    this.valuein = [{ value: '' }];
  }
}

export class Condition {
  id!: number;

  order!: number;

  type!: string;

  dataSource!: string;

  operator!: string;

  children!: Condition[];

  fields!: Field[];

  isSubCondition: boolean;

  constructor() {
    this.type = 'AND';
    this.operator = '=';
    this.children = [];
    this.dataSource = 'registro_movimentacao';
    this.isSubCondition = false;
  }
}

export enum ValueInEnum {
  CHAR_PIPE_VALUEIN = '|',
  CHAR_PIPE_QUERY = ',',
  CHAR_PIPE_VALUEIN_SPLIT = '\\|',
}

export interface ValueIn {
  value: ValueInEnum | string;
}

export default interface SelectFieldType {
  label: string;
  showOnDefaultValue: boolean;
  showOnFunctionParam: boolean;
  showOnRecordField: boolean;
  value: string;
};

export enum ConditionTypeEnum {
  EMPTY = 'EMPTY',
  AND = 'AND',
  OR = 'OR',
  COMA = 'COMA',
}
