import { SupportedLanguages, TranslactionsModel } from './lang-settings';

import { rule }  from './rule';
import { common } from './common';
import { error } from './error';

const supportedLanguages: SupportedLanguages[] = ['pt-BR', 'en-US'];

const langs: Record<string, TranslactionsModel> = Object.fromEntries(
  supportedLanguages.map(
    (lang): [string, TranslactionsModel] => [
      lang,
      {
        common: common[lang],
        error: error[lang],
        rule: rule[lang],
      },
    ]
  )
);

export default langs;
