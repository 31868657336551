import { CommonTranslations } from "../models/common-translaction-model";

export const ptBR: CommonTranslations = {
  confirm: 'Confirmar',
  add: 'Adicionar',
  new: 'Novo | Nova',
  remove: 'Remover',
  save: 'Salvar',
  cancel: 'Cancelar',
  search: 'Pesquisar',
  edit: 'Editar',
  copy: 'Copiar',
  back: 'Voltar',
  delete: 'Excluir',
  loading: 'Carregando...',
  actions: 'Ações',
  option: 'Opção | Opções',
  available: "Disponível | Disponíveis",
  selected: 'Selecionada | Selecionadas | Selecionado | Selecionados',
  condition: 'Condição',
  dialogHeader: 'Confirmar ação',
  dialogText: 'Texto de ajuda para explicar o que acontece por padrão em uma ação de Dialog.',
  enter: 'enter',
  noResultsTo: 'Nenhum resultado para',
  press: 'Pressione',
  and: 'E',
  or: 'Ou',
};
