export class CalendarMovementConfig {
  movementTypes?: string[]
  flFutureWindow?: boolean
  flRetroactiveWindow?: boolean
  daysOfWindow?: number
  flContractCutOffDate?: boolean
  flContractStartDate?: boolean
  specificDays?: number[]
  calendarId?: number
}


export class CalendarMovement {
  id?: number
  carrierId?: number
}
export class CalendarMovementForm extends CalendarMovement {
  configs?: CalendarMovementConfig[]
}

export class CalendarMovementModel {
  displayForm?: Boolean;
  loading?: Boolean;
  calendarMovements?: CalendarMovement[];
  form?: CalendarMovementForm;

  constructor() {
    this.displayForm = false;
    this.loading = false;
    this.calendarMovements = [];
    this.form = {};
  }
}
