<template>
  <v-dialog v-model="dialogInformationShow" persistent max-width="800">
    <v-card max-height="480" class="overflow-y-auto pa-2">
      <v-card-title class="pa-5">
        <v-row>
          <template v-if="violationRules && violationRules.length > 0 && !isBeneficiaryDuplicated">
            <v-col cols="12" style="color: #4d596a, font-weight: bold">
              <h3 style="color: #4d596a">Campos obrigatórios:</h3>
            </v-col>
            <v-col v-for="(item, index) in violationRules" :key="index" cols="12">
              <span style="color: #4d596a; word-break: break-word">{{ `${item.ruleName}` }}</span>
            </v-col>
          </template>

          <template v-if="translatedCodeMessage && codeMessage !== 'layout.column.config.size.allowed'">
            <v-col align="center" cols="12">
              <span style="color: #4d596a; word-break: break-word" v-html="translatedCodeMessage"></span>
            </v-col>
          </template>
          <template v-if="translatedCodeMessage && codeMessage === 'layout.column.config.size.allowed'">
            <v-col v-for="(item, index) in messages" :key="index" cols="12">
              <span style="color: #4d596a; word-break: break-word; font-size: 14px">{{ item }}</span>
            </v-col>
          </template>

          <template v-if="violationRules && violationRules.length > 0 && isBeneficiaryDuplicated">
            <v-card-subtitle v-for="(item, index) in violationRules" :key="index" cols="12">
              <h3 class="mb-2" style="color: #4d596a">Duplicidade de Beneficiário</h3>

              <span class="text-subtitle-2" style="color: #4d596a; word-break: break-word">{{ `Nome: ${item.insuredName}` }}</span> <br>
              <span class="text-subtitle-2" style="color: #4d596a; word-break: break-word">{{ `Tipo do Beneficiário: ${item.beneficiaryType}` }}</span> <br>
              <span class="text-subtitle-2" style="color: #4d596a; word-break: break-word">{{ `Contrato: ${item.contractId}` }}</span> <br>
              <span class="text-subtitle-2" style="color: #4d596a; word-break: break-word">{{ `Sub: ${item.subContractId}` }}</span>
            </v-card-subtitle>
          </template>

          <template v-if="isMovementRecordDuplicated">
            <v-card-subtitle>
              <h3 class="mb-4" style="color: #4d596a">Alerta de Movimentação</h3>

              <span class="text-subtitle-2" style="color: #4d596a; word-break: break-word">
                Já existe uma movimentação em andamento neste contrato para o beneficiário selecionado. <br>
                Aguarde a finalização ou cancele a movimentação existente para prosseguir com uma nova solicitação.
              </span>
            </v-card-subtitle>
          </template>
        </v-row>
      </v-card-title>

        <v-card-actions v-if="isMovementRecordDuplicated" class="my-2">
          <v-row class="px-2">
            <v-col cols="auto" v-if="viewMovementSkipCriticism" >
              <v-btn class="elevation-0" large color="#edeff1" @click="skipDuplicityCheck()">
                <v-icon left color="red">fas fa-long-arrow-alt-right</v-icon>
                <span class="red--text">Continuar mesmo assim</span>
              </v-btn>
            </v-col>

          <v-col align="end">
            <v-btn class="elevation-0" large color="#edeff1" @click="viewMovements()">
              <v-icon left color="primary">fas fa-search</v-icon>
              <span class="primary--text">Ver movimentação existente</span>
            </v-btn>

            <v-btn class="ml-5" large color="primary" @click="close()">
              <v-icon left>fas fa-long-arrow-alt-left</v-icon>
              <span>Voltar</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <v-card-actions v-else>
        <v-row justify="center">
          <v-col align="center" cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              outlined
              block
              @click="close()"
            >
              OK
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { store, observableMutations } from '@/shared/observable/store';
import messageTranslationMixin from '@/shared/mixins/messageErrors/messageTranslationMixin';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default ({
  name: 'RuleValidationsModal',

  data: () => ({
    dialogInformationShow: false,
    violationRules: [],
    translatedCodeMessage: '',
    codeMessage: '',
    messages: [],
    isBeneficiaryDuplicated: false,
    isMovementRecordDuplicated: false,
    beneficiaryContractedPlan: {},
    dependentContractedPlan: {},
    contracts: [],
    subContracts: [],
    dataRecord: [],
    isDependentInclusion: false,
    viewMovementSkipCriticism: false
  }),

  watch: {
    getObservableStore: {
      handler(val) {
        if (val) {
          if (val.data && Array.isArray(val.data)) {
            const errorMessages = val.data;
            let msgs = [];

            errorMessages.forEach((msgError, index) => {
              this.translatedCodeMessage = "";
              this.handleStatusCode(msgError);
              let message = this.translatedCodeMessage;

              if (index === 0 || !msgs.some((msg) => msg.codeMessage === msgError.codeMessage)) {
                if (msgError.fileName) {
                  message = `${this.translatedCodeMessage}<br>${msgError.fileName}`
                }

                msgs.push({
                  codeMessage: msgError.codeMessage,
                  message: index === 0
                    ? `${message}`
                    : `<br>${message}`,
                })
              } else {
                const msgIndex = msgs.findIndex((msg) => msg.codeMessage === msgError.codeMessage);

                if (msgError.fileName) {
                  message = msgError.fileName;
                }

                msgs[msgIndex].message += `<br>${message}`;
              }
            });

            this.translatedCodeMessage = "";

            msgs.forEach((msg) => {
              this.translatedCodeMessage += msg.message;
            });
          } else {
            if (sessionStorage.getItem('beneficiaryContractedPlan')) {
              this.beneficiaryContractedPlan = JSON.parse(sessionStorage.getItem('beneficiaryContractedPlan'));

              this.beneficiaryContractedPlan.plans.forEach((cp) => {
                this.contracts = cp.contracts;
                this.subContracts = cp.subcontracts;
              });
            }

            if (sessionStorage.getItem('dependentContractedPlan')) {
              this.isDependentInclusion = true;
              this.dependentContractedPlan = JSON.parse(sessionStorage.getItem('dependentContractedPlan'));
            } else {
              this.isDependentInclusion = false;
            }

            this.codeMessage = val.data.codeMessage;
            this.handleStatusCode(val.data);
          }

          this.dialogInformationShow = val.show;
          this.viewMovementSkipCriticism = this.hasPermission('sdi_mov_prosseguir_critica');
        }
      },
      deep: true,
    },

    dialogInformationShow: {
      handler(show) {
        if (!show) {
          this.cleanModalData();
        }
      }
    }
  },

  computed: {
    getObservableStore() {
      return store.dialogInformation;
    },
  },

  mixins: [
    messageTranslationMixin,
    AuthorityManagementMixin,
  ],

  methods: {
    handleStatusCode(data) {
      switch (data.code) {
        case 400:
          this.verifyExceptionWithStatusError400(data.codeMessage);
          break;
        case 404:
          this.verifyExceptionWithStatusError404(data.codeMessage);
          break;
        case 409:
          this.handleStatusCode409(data);
          break;
        case 412:
          this.verifyExceptionWithStatusError412(data.codeMessage);
          break;
        case 413:
          this.verifyExceptionWithStatusError413(data.codeMessage);
          break;
        case 422:
          this.verifyExceptionWithStatusError422(data.codeMessage);
          break;
        case 500:
          this.verifyExceptionWithStatusError500(data.codeMessage);
          break;
        default:
          if (!data.codeMessage) {
            this.violationRules = data;
          }
      }
    },
    handleStatusCode409(data) {
      if (data.codeMessage && data.codeMessage.includes('duplicated')) {
        if (data.beneficiaryDuplications) {
          this.handleBeneficiaryDuplicated(data);
        } else {
          this.handleMovementRecordDuplicated(data);
        }
      } else {
        this.handleNonDuplicatedError(data);
      }
    },
    handleBeneficiaryDuplicated(data) {
      this.isBeneficiaryDuplicated = true;

      const beneficiaryDuplicated = data.beneficiaryDuplications.map((beneficiary) => {
        const contract = this.findContractById(beneficiary.contractId);
        const subContract = this.findSubContractById(beneficiary.subContractId);

        return {
          contractedPlanId: beneficiary.contractedPlanId,
          insuredName: beneficiary.insuredName,
          contractId: contract ? this.isDependentInclusion ? contract.contractName : contract.text : null,
          subContractId: subContract ? this.isDependentInclusion ? contract.subcontractName : subContract.text : null,
          beneficiaryType: beneficiary.beneficiaryType === 'HOLDER' ? 'Titular' : 'Dependente',
        };
      });

      this.violationRules = beneficiaryDuplicated;
    },
    findContractById(contractId) {
      if (this.isDependentInclusion) {
        return contractId && this.dependentContractedPlan && this.dependentContractedPlan.length > 0 ? this.dependentContractedPlan.find((c) => Number(c.contractId) === Number(contractId)) : null;
      }
      return contractId && this.contracts && this.contracts.length > 0 ? this.contracts.find((c) => c.contract === Number(contractId)) : null;
    },
    findSubContractById(subContractId) {
      if (this.isDependentInclusion) {
        return subContractId && this.dependentContractedPlan && this.dependentContractedPlan.length > 0 ? this.dependentContractedPlan.find((c) => Number(c.subContractId) === Number(subContractId)) : null;
      }
      return subContractId && this.subContracts && this.subContracts.length > 0 ? this.subContracts.find((c) => c.subcontract === Number(subContractId)) : null;
    },
    handleMovementRecordDuplicated(data) {
      this.isMovementRecordDuplicated = true;
      this.dataRecord = data;
    },
    handleNonDuplicatedError(data) {
      this.isBeneficiaryDuplicated = false;
      this.isMovementRecordDuplicated = false;

      this.verifyExceptionWithStatusError409(data.codeMessage, data.message);

      if (data.codeMessage === 'layout.column.config.size.allowed') {
        this.messages = this.translatedCodeMessage.split(',');
      }
    },
    viewMovements() {
      this.dialogInformationShow = false;
      this.translatedCodeMessage = '';
      observableMutations.setDialogInformation({
        data: this.dataRecord,
        show: false,
        viewMovements: true,
      });
    },
    skipDuplicityCheck() {
      this.dialogInformationShow = false;
      this.translatedCodeMessage = '';
      observableMutations.setDialogInformation({
        data: {},
        show: false,
        skipDuplicityCheck: true,
      });
    },
    close() {
      this.dialogInformationShow = false;
      this.translatedCodeMessage = '';
      observableMutations.setDialogInformation({
        data: {},
        show: false,
      });
    },
    cleanModalData() {
      this.dialogInformationShow = false;
      this.violationRules = [];
      this.translatedCodeMessage = '';
      this.codeMessage = '';
      this.messages = [];
      this.isBeneficiaryDuplicated = false;
      this.isMovementRecordDuplicated = false;
      this.beneficiaryContractedPlan = {};
      this.dependentContractedPlan = {};
      this.contracts = [];
      this.subContracts = [];
      this.dataRecord = [];
      this.isDependentInclusion = false;
    },
  },
});
</script>
