import { RuleTranslations } from "../models/rule-translaction-model";

export const enUS: RuleTranslations = {
  title: "General Rules",
  self: "Rule",
  fields: {
    name: {
      label: "Search by name",
      title: "Rule Name",
      messages: {
        errors: {
          search: "An error occurred while searching for rules."
        }
      },
    },
    message: {
      label: "Search by message",
      title: "Message",
    },
    tag: {
      label: "Search by category",
      title: "Category",
      messages: {
        errors: {
          search: "An error occurred while searching for tags."
        },
        helper: "Type the category to include or create",
        helperKey: "Press ENTER to include a new one",
      },
    },
    movementType: {
      label: "Search by movement type",
      title: "Movement Type",
    },
    field: {
      label: "Search by criticized field",
      title: "Criticized Field",
    },
    ruleType: {
      label: "Search by rule type",
      title: "Rule Type",
    },
    criticism: {
      label: "Search by criticism",
      title: "Criticism",
      messages: {
        errors: {
          search: "An error occurred while searching for criticisms."
        },
      },
    },
  },
  table: {
    emptyMessage: "You have no rules",
    itemsPerPageText: "Records per page",
  },
  conditions: {
    type: "Type",
    value: "Value",
    param: "Parameter",
    operator: "Operator",
    firstField: "Field 1",
    secondField: "Field 2",
    thirdField: "Field 3",
    addCondition: "Add condition",
    deleteCondition: 'Delete condition',
    addSubCondition: "Add sub-condition",
    dataSource: 'Data source',
    messages: {
      helperKeyNewValue: 'Press ENTER to add a new value.',
      helperKeyNewDate: 'Press ENTER to enter a new date in the format "day/month/year"',
      placeholderDate: 'Date in the format "day/month/year"',
      noDataText: 'Select the data source'
    }
  },
  new: "New Rule",
  edit: "Edit Rule",
  create: "Create Rule",
  delete: "Delete Rule",
  copy: "Copy Rule",
  messages: {
    confirmDelete: "Are you sure you want to delete the rule below?",
    ruleDeleted: "Rule successfully deleted",
    ruleDeleteError: "Error deleting rule. Check if it is linked to other activities.",
  },
  movementTypeOptions: {
    implantation: "Implantation",
    inclusion: "Inclusion",
    exclusion: "Exclusion",
    alteration: "Alteration",
  },
  ruleTypeOptions: {
    dynamic: "Dynamic",
    script: "Script",
    groovy: "Groovy"
  },
  scriptLanguage: "Script language",
  defaultValueType: "Default value type",
  value: "Value",
  description: "Description",
  toCreateThisCategory: "to create this category",
};
