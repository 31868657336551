import { CommonTranslations } from "../models/common-translaction-model";

export const enUS: CommonTranslations = {
  confirm: "Confirm",
  add: "Add",
  new: "New | New",
  remove: "Remove",
  save: "Save",
  cancel: "Cancel",
  search: "Search",
  edit: "Edit",
  copy: "Copy",
  back: "Back",
  delete: "Delete",
  loading: "Loading...",
  actions: "Actions",
  available: "Avaliable | Avaliable",
  option: "Option | Options",
  selected: "Selected | Selecteds | Selected | Selecteds",
  condition: "Condition",
  dialogHeader: "Confirm action",
  dialogText: "Help text to explain what happens by default in a Dialog action.",
  enter: "Enter",
  noResultsTo: "No results for",
  press: "Press",
  and: "And",
  or: "Or",
};
